/**
 * Datapicker
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @credits http://xdsoft.net/jqplugins/datetimepicker/
 */
var datepicker = function(){
    var self = this;
    var current_year = new Date().getFullYear();

    var current_date = new Date();
    var day = current_date.getDate();
    var month = current_date.getMonth() + 1;
    
    // Min date of birth
    var min_year = current_date.getFullYear() - 95;
    var min_date_of_birth = min_year + '/' + month + '/' + day;
    
    // Max date of birth
    var max_year = current_date.getFullYear() - 5;
    var max_date_of_birth = max_year + '/' + month + '/' + day;

    self.setDatePicker = function( locale ){
        $('.js-datepicker').each(function(){
            $(this).datetimepicker({
                lang: locale,
                format:'d-m-Y',
                timepicker: false,
                scrollInput: false,
                minDate: 0, // Today
                maxDate: '+1970/01/31', // 30 days ahead
                closeOnDateSelect: true
            });
        });
    };

    self.setBirthDatePicker = function( locale ){
        $('.js-birth-datepicker').each(function(){
            $(this).datetimepicker({
                lang: locale,
                format: 'd-m-Y',
                timepicker: false,
                scrollInput: false,
                startDate: '1970/01/01',
                yearStart: min_year, // Year of today -95 years
                minDate: min_date_of_birth, // Date of today -95 years
                maxDate: max_date_of_birth, // Date of today -5 years
                yearEnd: max_year, // Year of today -2 years
                closeOnDateSelect: true
            });
        });
    };

    self.setChildBirthDatePicker = function( locale ){
        $('.js-birth-datepicker').each(function(){
            $(this).datetimepicker({
                lang: locale,
                format: 'd-m-Y',
                timepicker: false,
                scrollInput: false,
                startDate: '1990/01/01',
                yearStart: 1990,
                yearEnd: current_year,
                maxDate: 0,
                closeOnDateSelect: true
            });
        });
    };

    self.setTimePicker = function( locale ){ 
        $('.js-timepicker').each(function(){
            $(this).datetimepicker({
                lang: locale,
                format: 'H:i',
                datepicker: false,
                scrollInput: false,
                closeOnTimeSelect: true
            });
        });
    }

    self.setStartingDatePicker = function( locale ){ 
        $('.js-starting-datepicker').each(function(){
            $(this).datetimepicker({
                lang: locale,
                format:'d-m-Y',
                timepicker: false,
                scrollInput: false,
                minDate: 0, // Today
                maxDate: '+1970/01/31', // 30 days ahead
                closeOnDateSelect: true
            });

            // Event listener to the input field to detect when the user finishes typing
            $(this).on('blur', function() {
                var current_date = new Date();
                current_date.setHours(0, 0, 0, 0);
                var input_date = $(this).val();

                var future_date = new Date();
                future_date.setDate(current_date.getDate() + 30);
                console.log(future_date);
                // Parse the input date string into a Date object with d-m-Y format
                var parts = input_date.split('-');
                var input_date_formatted = new Date(parts[2], parts[1] - 1, parts[0]);
                
                // When input in the past clear the input
                if (current_date && input_date_formatted < current_date || input_date_formatted > future_date) {
                    $(this).val(new Date().toLocaleDateString('en-GB', {timeZone: 'Europe/Amsterdam'}).replace(/\//g, '-'));
                }
            });
        });
    }
}